import React, { useCallback, useEffect, useState } from 'react'

const autoDecodeIds = (key, value) => {
    try {
        if (typeof value === 'string' && atob(value).includes(':')) {
            return `${value} [${atob(value)}]`
        }
        return value
    } catch {
        return value
    }
}

const stringify = (data: any) => JSON.stringify(data, autoDecodeIds, 2)

interface DebugContextInterface {
    active: boolean
    stringify: (data: any) => string
}

export const DebugContext = React.createContext<DebugContextInterface>({
    active: false,
    stringify,
})

export const useDebug = () => React.useContext(DebugContext)

const codes = ['idspispopd', 'iddqd', 'idkfa']

interface ControlProps {
    onToggle: () => void
    onStop: () => void
    visible: boolean
    active: boolean
}

const DebuggerControls = ({ onToggle, onStop, visible, active }: ControlProps) => {
    if (!active) return null
    return (
        <div style={{ position: 'fixed', bottom: 5, left: 5 }}>
            <button type="button" onClick={onToggle} className="btn btn-small btn-success">
                {visible ? 'Hide debug' : 'Show debug'}
            </button>{' '}
            <button type="button" onClick={onStop} className="btn btn-small btn-danger">
                {'Stop debug'}
            </button>
        </div>
    )
}

export const DebugProvider = ({ children }) => {
    const [active, setActive] = useState(localStorage.getItem('debug') === 'true')
    const [visible, setVisible] = useState(localStorage.getItem('debug') === 'true')

    const [keystroke, setKeystroke] = useState('')

    useEffect(() => {
        const keydown = (e) => {
            setKeystroke((prev) => {
                const keystroke = prev + e.key?.toLowerCase()
                if (codes.find((code) => code.startsWith(keystroke))) return keystroke
                return ''
            })
        }
        document.addEventListener('keydown', keydown)
        return () => {
            document.removeEventListener('keydown', keydown)
        }
    }, [])

    useEffect(() => {
        if (codes.includes(keystroke)) {
            setActive(true)
            setVisible(true)
            localStorage.setItem('debug', 'true')
        }
    }, [keystroke])

    const handleToggle = useCallback(() => {
        setVisible((prev) => !prev)
    }, [])

    const handleStop = useCallback(() => {
        setVisible(false)
        setActive(false)
        localStorage.removeItem('debug')
    }, [])

    return (
        <DebugContext.Provider value={{ active: active && visible, stringify }}>
            {children}
            <DebuggerControls
                active={active}
                visible={visible}
                onStop={handleStop}
                onToggle={handleToggle}
            />
        </DebugContext.Provider>
    )
}
