import { gql } from '@urql/core'
import { ClientPostFragment } from './client'
import { FileFragment, ImageFragment, VideoFragment } from './posts'
import { AuthorFragment, SimpleRoleFragment } from './user'

export const employeesQuery = gql`
    query employees(
        $location: ID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $search: String
    ) {
        employees(
            location: $location
            first: $first
            last: $last
            before: $before
            after: $after
            search: $search
        ) {
            pageInfo {
                endCursor
                startCursor
                hasPreviousPage
                hasNextPage
            }
            edges {
                node {
                    id
                    slug
                    photo
                    firstName
                    lastName
                    fullName
                    type
                    relation
                    reference
                    email
                    created
                    divisions {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    rights {
                        update
                        delete
                    }
                    user {
                        aboutMe
                    }
                }
            }
        }
    }
`

export const updateLocationMutation = gql`
    mutation UpdateLocation($input: UpdateLocationInput!) {
        updateLocation(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateLocationLogoMutation = gql`
    mutation UpdateLocationLogo($input: UpdateLocationLogoInput!) {
        updateLocationLogo(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateLocationCoverPhotoMutation = gql`
    mutation UpdateLocationCoverPhoto($input: UpdateLocationCoverPhotoInput!) {
        updateLocationCoverPhoto(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const LocationPostFragment = gql`
    ${ImageFragment}
    ${FileFragment}
    ${VideoFragment}
    ${AuthorFragment}
    ${SimpleRoleFragment}
    fragment LocationPost on LocationPostNode {
        id
        body
        bodyHtml
        created
        video {
            ...Video
        }
        images {
            edges {
                node {
                    ...Image
                }
            }
        }
        files {
            edges {
                node {
                    ...File
                }
            }
        }
        likes {
            totalCount
            edges {
                node {
                    ...Role
                }
            }
        }
        author {
            ...Author
        }
        rights {
            update
            delete
            createComment
            createVideo
        }
    }
`

export const LocationThreadFragment = gql`
    ${LocationPostFragment}
    fragment LocationThread on LocationThreadNode {
        id
        isLocked
        commentCount
        target
        firstPost {
            ...LocationPost
            copyCount
        }
    }
`

export const locationPostsAndCommentsForThread = gql`
    ${LocationThreadFragment}
    ${ClientPostFragment}
    query locationPostsAndCommentsForThread(
        $threadId: ID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $searchQuery: String
    ) {
        node(id: $threadId) {
            ... on LocationThreadNode {
                ...LocationThread
                comments(
                    first: $first
                    last: $last
                    before: $before
                    after: $after
                    searchCommentQuery: $searchQuery
                ) {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasPreviousPage
                        hasNextPage
                    }
                    edges {
                        node {
                            ...ClientPost
                            client
                            thread
                        }
                    }
                }
            }
        }
    }
`

export const copyCountForLocationThread = gql`
    query copyCountForLocationThread($threadId: ID!) {
        node(id: $threadId) {
            ... on LocationThreadNode {
                firstPost {
                    copyCount
                }
            }
        }
    }
`

export const commentsForLocationThread = gql`
    ${ClientPostFragment}
    query commentsForLocationThread(
        $threadId: ID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $searchQuery: String
    ) {
        node(id: $threadId) {
            ... on LocationThreadNode {
                comments(
                    first: $first
                    last: $last
                    before: $before
                    after: $after
                    searchCommentQuery: $searchQuery
                ) {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasPreviousPage
                        hasNextPage
                    }
                    edges {
                        node {
                            ...ClientPost
                            client
                            thread
                        }
                    }
                }
            }
        }
    }
`

export const locationForumThreads = gql`
    ${LocationThreadFragment}
    query locationForumThreads(
        $forumId: ID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $searchQuery: String
    ) {
        node(id: $forumId) {
            ... on LocationForumNode {
                threads(
                    first: $first
                    last: $last
                    before: $before
                    after: $after
                    searchThreadQuery: $searchQuery
                ) {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasPreviousPage
                        hasNextPage
                    }
                    edges {
                        node {
                            ...LocationThread
                        }
                    }
                }
            }
        }
    }
`

export const createLocationThread = gql`
    ${LocationPostFragment}
    mutation createLocationThread($input: CreateLocationThreadInput!) {
        createLocationThread(input: $input) {
            errors {
                field
                messages
            }
            thread {
                id
                firstPost {
                    ...LocationPost
                }
            }
        }
    }
`

export const updateLocationPost = gql`
    ${LocationPostFragment}
    mutation updateLocationPost($input: UpdateLocationPostInput!) {
        updateLocationPost(input: $input) {
            errors {
                field
                messages
            }
            post {
                ...LocationPost
            }
        }
    }
`

export const deleteLocationPost = gql`
    mutation deleteLocationPost($input: DeleteLocationPostInput!) {
        deleteLocationPost(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const clientThreadsFor = gql`
    query clientThreadsFor($locationThread: ID!) {
        clientThreadsFor(locationThread: $locationThread) {
            edges {
                node {
                    client
                    thread
                }
            }
        }
    }
`

export const updateLocationPostImageTitle = gql`
    mutation updateLocationPostImageTitle($input: UpdateLocationPostImageTitleInput!) {
        updateLocationPostImageTitle(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
