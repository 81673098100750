import { gql } from '@urql/core'

export const activeUser = gql`
    query activeUser {
        activeUser {
            id
            email
            firstName
            lastName
            photo
            aboutMe
            locale
        }
    }
`

export const activeRole = gql`
    query activeRole {
        activeRole {
            id
            fullName
            label
            relation
            photo
            type
            isMainContact
            emailDigest
            created

            rights {
                creatableTargets
                readableTargets
                emailDigestChoices
                promote
                delete
                update
            }

            clients(first: 1) {
                totalCount
                edges {
                    node {
                        id
                        slug
                        fullName
                    }
                }
            }

            location {
                id
                slug
                logo
                coverPhoto
                name
                organisation
                phone
                about
                address
                email
                website
                welcomePostBody
                isHideManagers
                isHidePremployees
                allowAllDivisionsPlusnurse
                clientInviteCustomText
                employeeInviteCustomText
                forum

                tz
                locale

                allDivisions {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }

                divisions {
                    edges {
                        node {
                            id
                            name
                            forum
                        }
                    }
                }

                rights {
                    update
                    delete
                    createDivision
                    createClient
                    createAppointment
                    createPost
                    createEmployeeRegular
                    createEmployeePlus
                    createEmployeeManager
                    createEmployeePr
                    createEmployeeVolunteer
                }

                showClientReference
                showEmployeeReference
            }
        }
    }
`

export const activateRole = gql`
    mutation activateRole {
        activateRole {
            ok
        }
    }
`

export const allRoles = gql`
    query allRoles {
        allRoles {
            edges {
                node {
                    id
                    label
                    parentPhoto
                    type
                    isMainContact
                    created
                }
            }
        }
    }
`
