import classNames from 'classnames'
import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import Clickoutside from '../../components/clickoutside'
import Debug from '../../components/debug'
import Figure from '../../components/figure'
import TextOverflow from '../../components/nowrap'
import Trans from '../../components/trans'
import LocaleContext, { browserLocale } from '../../context/locale'
import { useNotificationStore } from '../../context/notification'
import RoleContext from '../../context/role'
import UserContext from '../../context/user'
import { useIsMobile } from '../../helpers/device'
import { useOrgLocation } from '../../hooks/data/user'
import EmployeeMenu from './menu/employee'
import FamilyMenu from './menu/family'
import MenuHeader from './menu/header'
import PersonalMenu from './menu/personal'

import { ReactComponent as Bell } from '../../../svg/bell-filled.svg'
import { ReactComponent as Chevron } from '../../../svg/chevron-large.svg'
import { ReactComponent as Familienet } from '../../../svg/familienet.svg'
import { ReactComponent as Familynet } from '../../../svg/familynet.svg'
import { ReactComponent as Menu } from '../../../svg/menu.svg'

import '../../../scss/react/menus.scss'
import '../../../scss/react/nav.scss'

interface NavBarProps {
    children?: React.ReactNode
    home?: string
}

export const NavPublic = () => {
    const locale = browserLocale()
    return (
        <div className="inner">
            <h2 className="logo">
                <a href="/">
                    {locale.includes('nl') && <Familienet />}
                    {!locale.includes('nl') && <Familynet />}
                </a>
            </h2>
        </div>
    )
}

export const NavBar = ({ children, home }: NavBarProps) => {
    const { locale } = useContext(LocaleContext)
    return (
        <div className="inner">
            <h2 className="logo">
                <Link to={home || '/'}>
                    {locale.includes('nl') && <Familienet />}
                    {!locale.includes('nl') && <Familynet />}
                </Link>
            </h2>
            {children}
        </div>
    )
}

const MenuButton = ({ showMenu, onHide, onToggle, children }) => {
    const { user } = useContext(UserContext)
    return (
        <Clickoutside onClickOutside={onHide}>
            <div className="menu">
                <button type="button" onClick={onToggle}>
                    <Figure personIcon borderGreen image={user?.photo} />
                    <span>
                        <Trans label="Menu" />
                    </span>
                    {!showMenu && <Menu />}
                    {showMenu && <Chevron className="chevron" />}
                </button>
                {showMenu && children}
            </div>
        </Clickoutside>
    )
}

export const NavUser = () => {
    const [showMenu, setShowMenu] = useState(false)

    const isMobile = useIsMobile()

    const hideMenu = useCallback(() => {
        setShowMenu(false)
    }, [])

    const toggleMenu = useCallback(() => {
        setShowMenu((showMenu) => !showMenu)
    }, [])

    return (
        <NavBar>
            {!isMobile && (
                <MenuButton showMenu={showMenu} onHide={hideMenu} onToggle={toggleMenu}>
                    <div className="dropdown-menu">
                        <MenuHeader />
                        <PersonalMenu onClick={hideMenu} />
                    </div>
                </MenuButton>
            )}
        </NavBar>
    )
}

export const NavRole = () => {
    const [showMenu, setShowMenu] = useState(false)
    const { home, isFamily, isEmployee, role } = useContext(RoleContext)
    const { unreadCount } = useNotificationStore()

    const isMobile = useIsMobile()
    const location = useOrgLocation()

    const hideMenu = useCallback(() => {
        setShowMenu(false)
    }, [])

    const toggleMenu = useCallback(() => {
        setShowMenu((showMenu) => !showMenu)
    }, [])

    return (
        <NavBar home={home}>
            <Link className="role" to={`/location/${location?.slug}`}>
                <Figure locationIcon borderGreen image={location?.logo} />
                <TextOverflow maxWidth={250}>{`${location?.name}`}</TextOverflow>
            </Link>
            <Debug data={role?.id} />
            {!isMobile && (
                <Link
                    className={classNames('btn', 'notifications', { unread: unreadCount })}
                    to="/notifications"
                >
                    <Bell />
                    {!!unreadCount && <span className="badge">{unreadCount}</span>}
                </Link>
            )}

            {!isMobile && (
                <MenuButton showMenu={showMenu} onHide={hideMenu} onToggle={toggleMenu}>
                    <div className="dropdown-menu">
                        <MenuHeader />
                        {!isFamily && !isEmployee && <PersonalMenu onClick={hideMenu} />}
                        {!!isEmployee && <EmployeeMenu onClick={hideMenu} />}
                        {!!isFamily && <FamilyMenu onClick={hideMenu} />}
                    </div>
                </MenuButton>
            )}
        </NavBar>
    )
}
