import Cookie from 'js-cookie'
import React, { useContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { LoadingFullPage } from '../components/loading/page'
import { activeRole } from '../graphql/queries/me'
import { RoleNode } from '../graphql/types'
import { t } from '../helpers/i18n'
import { parseToken } from '../helpers/id'
import { useToastyQuery } from '../hooks/data'
import { useRoles } from '../hooks/data/user'
import { useQuery } from './route'
import { UserToken } from './user'

interface RoleContextInterface {
    home: string
    isPR: boolean
    isFamily: boolean
    isEmployee: boolean
    hasRole: boolean
    role?: RoleNode
}

const RoleContext = React.createContext<RoleContextInterface>({} as RoleContextInterface)
export default RoleContext

export const useRoleContext = () => useContext(RoleContext)

export const RoleProvider = ({ children }) => {
    const hasRole = !!Cookie.get('X-Role')

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { withRoleId, userToken, next } = useQuery()

    const [roleResult] = useToastyQuery({
        query: activeRole,
        pause: !hasRole,
    })

    const role = useMemo<RoleNode | undefined>(() => roleResult.data?.activeRole, [roleResult.data])

    // @TODO fetch and store roles in user context
    const [roles, rolesResult] = useRoles()

    const isFamily = useMemo(() => !!role?.type?.includes('FAMILY'), [role])
    const isEmployee = useMemo(() => !!role?.type?.includes('EMPLOYEE'), [role])
    const isPR = useMemo(() => role?.type === 'EMPLOYEE_PR', [role])
    const client = useMemo(() => isFamily && role?.clients?.edges?.[0]?.node, [isFamily, role])

    // home link
    const home = useMemo(() => {
        if (isPR) return '/group-message'
        if (isEmployee) return '/clients'
        if (isFamily && client) return `/client/${client.slug}`
        return '/account/settings'
    }, [client, isEmployee, isFamily, isPR])

    // need a role
    useEffect(() => {
        if (roleResult.fetching) return
        if (rolesResult.fetching) return

        // Handle role switching if withRoleId is present
        if (withRoleId || next?.includes('withRoleId')) {
            let roleId = withRoleId
            if (next?.includes('withRoleId')) {
                const nextParams = new URLSearchParams(next)
                roleId = nextParams.get('withRoleId')
            }

            // Find the role in the list
            const matchingRole = roles.find((r) => r.id === roleId)
            if (matchingRole) {
                Cookie.set('X-Role', roleId, { expires: null }) // only remember for session duration
                navigate(next || pathname)
                return
            }
        }

        // take the role from the token and remove from query
        if (userToken) {
            const token = parseToken<UserToken>(userToken)
            Cookie.set('X-Role', token.sub, { expires: null }) // only remember for session duration
            window.location.href = next || pathname
            return
        }

        // follow existing next link first
        if (next && pathname === '/') {
            return navigate(next)
        }

        // user has a role and it's in the list
        if (role) return

        // if no roles
        if (roles.length === 0) {
            // stay in account section if already there
            if (pathname.startsWith('/account')) return
            // navigate to account section
            return navigate(home)
        }

        // if single role
        if (roles.length === 1) {
            Cookie.set('X-Role', roles?.[0].id, { expires: null }) // only remember for session duration
            // syntetic redirect this time with role
            return navigate(pathname)
        }

        // If invited for a new role
        if (next && next.startsWith('/account/invite')) {
            return navigate(next)
        }

        // if multiple roles and on account invite, stay there
        if (pathname.startsWith('/account/invite')) {
            Cookie.set('X-Role', roles?.[0].id, { expires: null }) // only remember for session duration
            return navigate(pathname)
        }

        // if multiple roles
        navigate(`/account/pick-role?next=${pathname}`)
    }, [
        home,
        navigate,
        next,
        pathname,
        role,
        roleResult.fetching,
        roles,
        rolesResult.fetching,
        userToken,
        withRoleId,
    ])

    const context = useMemo(
        () => ({
            home,
            isPR,
            isFamily,
            isEmployee,
            role,
            hasRole: !!(hasRole && role),
        }),
        [home, isPR, isFamily, isEmployee, hasRole, role]
    )

    // We need role to render these routes
    return (
        <RoleContext.Provider value={context}>
            {!roleResult.fetching && (!!role || pathname.startsWith('/account')) && children}
            {!role && !pathname.startsWith('/account') && (
                <LoadingFullPage>
                    <p>{t('Loading account...')}</p>
                </LoadingFullPage>
            )}
        </RoleContext.Provider>
    )
}
