import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useHash } from '../context/route'
import { scrollToTargetTop } from '../helpers/scroll'

const useScrollToHash = ({ ref, id }) => {
    const { hash, changeHash } = useHash()
    const navigate = useNavigate()

    useEffect(() => {
        if (!id) return
        if (!hash) return
        if (id !== hash) return

        // if route contains a hash with this id
        // scroll to this componnent
        if (!ref.current) return

        scrollToTargetTop(ref.current, 30, 'instant')

        // remove the hash
        setTimeout(() => {
            navigate(changeHash(null), { replace: true })
        }, 1500)
    }, [changeHash, ref, hash, id, navigate])
}

export const useScrollToNew = (queryString: string) => {
    useEffect(() => {
        // check if there are new items in the url
        const queryParams = new URLSearchParams(queryString)
        const newPost = queryParams.get('newPost')
        const newLike = queryParams.get('newLike')
        const newThread = queryParams.get('newThread')

        let listLength = 0
        listLength += (!!newPost && newPost?.split(',').length) || 0
        listLength += (!!newLike && newLike?.split(',').length) || 0
        listLength += (!!newThread && newThread?.split(',').length) || 0

        if (!listLength) return

        // observeer the dom until we count the number of new items we expect
        const observer = new MutationObserver((mutationsList) => {
            const newElements = document.querySelectorAll('.thread-new, .like-new, .comment-new')

            if (newElements.length >= listLength) {
                // stop observing and scroll to the first new element
                observer.disconnect()
                newElements[0].scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                })
            }
        })

        // Observer starten op de body
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        })

        // Opruimen bij component unmount
        return () => {
            observer.disconnect()
        }
    }, [queryString]) // Leg
}

export default useScrollToHash
