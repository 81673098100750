interface Props {
    maxLength?: number
    maxWidth?: React.CSSProperties['maxWidth']
    children: string
}

export default function TextOverflow({ children, maxWidth, maxLength }: Props) {
    if (maxLength) {
        if (children.length <= maxLength) return <>{children}</>
        return (
            <span aria-label={children} title={children}>
                {children.slice(0, maxLength)}
                {'...'}
            </span>
        )
    }

    return (
        <span
            style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth,
                overflow: 'hidden',
            }}
            className="text-overflow"
            aria-label={children}
        >
            {children}
        </span>
    )
}
