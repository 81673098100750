import { gql } from '@urql/core'

export const updateEmail = gql`
    mutation updateEmail($input: UpdateEmailInput!) {
        updateEmail(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updatePasswordMutation = gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
        updatePassword(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeeRegular = gql`
    mutation UpdateEmployeeRegular($input: UpdateEmployeeRegularInput!) {
        updateEmployeeRegular(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeePlus = gql`
    mutation UpdateEmployeePlus($input: UpdateEmployeePlusInput!) {
        updateEmployeePlus(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeeManager = gql`
    mutation UpdateEmployeeManager($input: UpdateEmployeeManagerInput!) {
        updateEmployeeManager(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeeVolunteer = gql`
    mutation UpdateEmployeeVolunteer($input: UpdateEmployeeVolunteerInput!) {
        updateEmployeeVolunteer(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeePr = gql`
    mutation UpdateEmployeePr($input: UpdateEmployeePrInput!) {
        updateEmployeePr(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const roleDeletePreconditions = gql`
    query roleDeletePreconditions($role: ID!) {
        roleDeletePreconditions(role: $role) {
            conditions {
                message
            }
        }
    }
`

export const deleteRole = gql`
    mutation deleteRole($input: DeleteRoleInput!) {
        deleteRole(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const deleteAccountMutation = gql`
    mutation DeleteAccount($input: DeleteAccountInput!) {
        deleteAccount(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateProfile = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
        updateProfile(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateUser = gql`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateProfilePhotoMutation = gql`
    mutation UpdateProfilePhoto($input: UpdateProfilePhotoInput!) {
        updateProfilePhoto(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const SimpleRoleFragment = gql`
    fragment Role on RoleNode {
        id
        slug
        fullName
        relation
        photo
        type
    }
`

export const AuthorFragment = gql`
    fragment Author on AuthorNode {
        ... on RoleNode {
            id
            fullName
            relation
            photo
            type
            isMainContact
            location {
                id
                name
            }
        }
        ... on UnknownRole {
            fullName
            relation
            photo
        }
    }
`

export const FullRoleFragment = gql`
    fragment Role on RoleNode {
        id
        slug
        email
        fullName
        relation
        photo
        type
        reference
        user {
            aboutMe
        }
        clients {
            edges {
                node {
                    id
                    fullName
                    photo
                    slug
                }
            }
        }
        divisions {
            edges {
                node {
                    id
                    name
                }
            }
        }
        location {
            id
            name
        }
        rights {
            update
            delete
            promote
        }
    }
`

export const getRole = gql`
    ${FullRoleFragment}
    query getRole($id: ID!) {
        node(id: $id) {
            id
            ... on RoleNode {
                ...Role
            }
        }
    }
`

export const getEmployeeBySlug = gql`
    ${FullRoleFragment}
    query employeeBySlug($slug: String!) {
        employeeBySlug(slug: $slug) {
            id
            ... on RoleNode {
                ...Role
                reference
            }
        }
    }
`

export const promoteToFamilyContact = gql`
    mutation promoteToFamilyContact($input: PromoteToFamilyContactInput!) {
        promoteToFamilyContact(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateUserDevice = gql`
    mutation updateUserDevice($input: UpdateUserDeviceInput!) {
        updateUserDevice(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const getUserPreference = gql`
    query {
        userPreference {
            sorting
        }
    }
`

export const updateUserPreference = gql`
    mutation UpdateUserPreference($sorting: JSONString!) {
        updateUserPreference(sorting: $sorting) {
            userPreference {
                sorting
            }
        }
    }
`
