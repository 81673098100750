import { setUser } from '@sentry/react'
import Cookie from 'js-cookie'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClientOptions, Provider as UrqlProvider, createClient } from 'urql'

import { LoadingFullPage } from '../components/loading/page'
import { authorizedClientConfig } from '../graphql/clients'
import { activeUser } from '../graphql/queries/me'
import { RoleNode, UserNode } from '../graphql/types'
import { t } from '../helpers/i18n'
import { parseToken } from '../helpers/id'
import { QueryResult, ReloadQuery, useToastyMutation, useToastyQuery } from '../hooks/data'
import LocaleContext from './locale'
import { useQuery } from './route'
import { getDeviceIdAndPermission } from '../app/push-notification'
import { updateUserDevice } from '../graphql/queries/user'

export interface UserToken {
    sub: RoleNode['id']
    email: RoleNode['email']
    iat: number
    exp: number
}

const UserContext = React.createContext<{ user?: UserNode; reload: ReloadQuery }>({
    user: undefined,
    reload: async () => undefined,
})
export default UserContext

export const useUser = (props?: { pause?: boolean }): [UserNode, QueryResult, ReloadQuery] => {
    const { setLocale } = useContext(LocaleContext)

    const [result, reload] = useToastyQuery({
        query: activeUser,
        pause: props?.pause,
    })

    const user: UserNode = useMemo(
        () => !result.fetching && result.data?.activeUser,
        [result.data, result.fetching]
    )

    useEffect(() => {
        if (!user?.locale) return
        setLocale(user.locale)
    }, [setLocale, user?.locale])

    useEffect(() => {
        if (!user) return
        setUser({
            id: user.id,
            username: `${user.firstName} ${user.lastName}`,
        })
    }, [user])

    return useMemo(() => [user, result, reload], [user, result, reload])
}

export const useUserContext = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
    const role = Cookie.get('X-Role')

    const { pathname, search } = useLocation()
    const fullPath = encodeURIComponent(`${pathname}${search}`)
    const navigate = useNavigate()
    const query = useQuery()

    const [user, userResult, reload] = useUser()
    const [deviceId, setDeviceId] = useState<string | null>(null)

    const authUrl = process.env.REACT_APP_LOGIN as string
    const isAtAuth = pathname.startsWith(authUrl)

    // need a user go to login
    useEffect(() => {
        if (userResult.fetching) return
        if (isAtAuth) return

        if (query?.userToken) {
            const token = parseToken<UserToken>(query.userToken)
            if (!user?.email || (user?.email && token.email !== user.email)) {
                console.log(fullPath)
                return navigate(`${authUrl}?next=${fullPath}&userToken=${query.userToken}`)
            }
        }

        if (user) return
        navigate(pathname !== '/' ? `${authUrl}?next=${fullPath}` : authUrl)
    }, [
        authUrl,
        isAtAuth,
        navigate,
        pathname,
        query.userToken,
        fullPath,
        user,
        userResult.fetching,
    ])

    // has a user go to root
    useEffect(() => {
        if (userResult.fetching) return
        if (!user) return
        if (!isAtAuth) return
        navigate('/')
    }, [isAtAuth, navigate, pathname, user, userResult.fetching])

    // Create new graphql client (with its own cache per role)
    const roleClient = useMemo(() => {
        return createClient(authorizedClientConfig as ClientOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role])

    // Fetch deviceId when user is logged in
    const [, executeUpdateUserDevice] = useToastyMutation(updateUserDevice)
    useEffect(() => {
        if (user && !userResult.fetching && !deviceId) {
            // Assuming a function getDeviceId retrieves the device ID
            getDeviceIdAndPermission().then(([deviceId, isActive]) => {
                if (deviceId) {
                    setDeviceId(deviceId)
                    executeUpdateUserDevice({
                        input: {
                            deviceId,
                            isActive,
                        },
                    })
                }
            })
        }
    }, [user, userResult.fetching, deviceId, executeUpdateUserDevice])

    return (
        <UserContext.Provider value={{ user, reload }} key={role}>
            {!userResult.fetching && isAtAuth && children}
            {!userResult.fetching && !!user && (
                <UrqlProvider value={roleClient}>{children}</UrqlProvider>
            )}
            {userResult.fetching && (
                <LoadingFullPage>
                    <p>{t('Loading account...')}</p>
                </LoadingFullPage>
            )}
        </UserContext.Provider>
    )
}
