import { useCallback, useMemo } from 'react'
import { AnyVariables, useMutation, useQuery } from 'urql'

import Notification from '../../helpers/toast'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

interface ReloadQueryProps<Args> {
    query: any
    variables?: Args
    pause?: boolean
}

export interface QueryResult {
    data?: null | any
    error?: any
    fetching: boolean
    stale: boolean
}
export interface MutationResult {
    data?: null | any
    error?: any
    fetching: boolean
}

export type ReloadQuery = () => Promise<void>
type UseReloadQuery = [QueryResult, ReloadQuery]

export function useToastyQuery<Args = any>({
    query,
    variables,
    pause,
}: ReloadQueryProps<Args>): UseReloadQuery {
    const [result, reexecute] = useQuery({
        query,
        variables: variables as AnyVariables,
        pause,
    })

    // find a way to await for fetching to become false again
    const reload = useCallback(async () => {
        await reexecute({
            requestPolicy: 'network-only',
        })

        await sleep(1024) // wait a few cycles to ensure reexecute
    }, [reexecute])

    return useMemo(() => [result, reload], [reload, result])
}

type UseMutation = [MutationResult, (...args: any) => Promise<MutationResult>]

export const useToastyMutation = (mutation): UseMutation => {
    const [result, execute] = useMutation(mutation)

    const executor = useCallback(
        async (...args: any): Promise<MutationResult['data']> => {
            const result = await execute(...args)
            if (typeof result.data === 'object') {
                // find error in data
                Object.keys(result.data).forEach((key) => {
                    const errors = result.data[key]?.errors
                    if (!errors) return
                    console.log('errors', errors)
                    if (process.env.REACT_APP_LOGGER !== 'on') return
                    errors.forEach(({ field, messages }) => {
                        messages.forEach((message) => {
                            Notification.error({
                                title: `Field error: ${field}`,
                                message,
                            })
                        })
                    })
                })
            }
            return result
        },
        [execute]
    )

    return [result, executor]
}
