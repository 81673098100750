import classnames from 'classnames'
import React, { useEffect } from 'react'

import { ReactComponent as Organisation } from '../../svg/organisation.svg'
import { ReactComponent as Person } from '../../svg/person.svg'

import '../../scss/react/figure.scss'

interface FigureProps {
    image?: string | null
    alt?: string
    fallback?: React.ReactNode
    children?: React.ReactNode
    className?: string
    personIcon?: boolean
    locationIcon?: boolean
    borderWhiteWithShadow?: boolean
    borderGreen?: boolean
    borderSpot?: boolean
    borderNone?: boolean
}

const Figure = ({
    image,
    alt,
    borderGreen,
    fallback,
    children,
    className,
    borderWhiteWithShadow,
    locationIcon,
    personIcon,
    borderSpot,
    borderNone,
}: FigureProps) => {
    // reload the page when browser is not refreshed in one hour
    useEffect(() => {
        if (!image || typeof image !== 'string' || !image.includes('Expires')) return
        const url = new URL(image)
        const expiresIn =
            url.searchParams.has('Expires') &&
            parseInt(url.searchParams.get('Expires') as string, 10) - Date.now() / 1000
        if (!expiresIn) return
        if (expiresIn < 60) {
            window.location.reload()
        }
    }, [image])

    // no image or image is one of the default images
    const noImage =
        !image ||
        typeof image !== 'string' ||
        (typeof image === 'string' && image?.includes('public/static/img/avatar'))

    // alternative to original image
    locationIcon =
        locationIcon || !!(typeof image === 'string' && image?.includes('avatar-location.png'))

    // alternative to original image and
    // if location icon is set, it is not a person icon
    personIcon = locationIcon
        ? false
        : personIcon || !!(typeof image === 'string' && image?.includes('avatar.png'))

    const classNames = classnames(className, {
        'figure-person-icon': personIcon,
        'figure-location-icon': locationIcon,
        'figure-white-with-shadow': borderWhiteWithShadow,
        'figure-border-success': borderGreen,
        'figure-border-spot': borderSpot,
        'figure-border-none': borderNone,
    })

    if (noImage) {
        return (
            <figure className={classnames('no-image', classNames)}>
                {children}
                {fallback}
                {personIcon && <Person />}
                {locationIcon && <Organisation />}
            </figure>
        )
    }

    return (
        <figure className={classNames}>
            <img src={image} alt={alt} loading="lazy" />
            {children}
        </figure>
    )
}

export default Figure
