import { gql } from '@urql/core'

export const createFamilyRegularInviteMutation = gql`
    mutation CreateFamilyRegularInvite($input: CreateFamilyRegularInviteInput!) {
        createFamilyRegularInvite(input: $input) {
            id
            errors {
                field
                messages
            }
        }
    }
`

export const authorizedInvite = gql`
    query authorizedInvite($acceptCode: String!) {
        invite(acceptCode: $acceptCode) {
            ... on FamilyInviteNode {
                id
                firstName
                lastName
                email
                message
                created
                lastSent
                client
                type
            }
            ... on EmployeeInviteNode {
                id
                firstName
                lastName
                email
                message
                created
                lastSent
                divisions
                type
            }
        }
    }
`

export const acceptInvite = gql`
    mutation acceptInvite($input: AcceptInviteInput!) {
        acceptInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const familyInvites = gql`
    query familyInvites($client: ID!, $last: Int) {
        familyInvites(client: $client, last: $last) {
            edges {
                node {
                    id
                    firstName
                    lastName
                    email
                    message
                    created
                    lastSent
                    rights {
                        update
                        delete
                    }
                }
            }
        }
    }
`

export const employeeInvites = gql`
    query EmployeeInvites($location: ID!, $search: String) {
        employeeInvites(location: $location, search: $search) {
            edges {
                node {
                    id
                    email
                    type
                    divisions
                    clients
                    firstName
                    lastName
                    message
                    created
                    lastSent
                    reference
                    rights {
                        delete
                        update
                    }
                }
            }
        }
    }
`

export const deleteFamilyInvite = gql`
    mutation deleteFamilyInvite($input: DeleteFamilyInviteInput!) {
        deleteFamilyInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateFamilyRegularInvite = gql`
    mutation updateFamilyRegularInvite($input: UpdateFamilyRegularInviteInput!) {
        updateFamilyRegularInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateFamilyContactInvite = gql`
    mutation updateFamilyContactInvite($input: UpdateFamilyContactInviteInput!) {
        updateFamilyContactInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const createEmployeeRegularInvite = gql`
    mutation CreateEmployeeRegularInvite($input: CreateEmployeeRegularInviteInput!) {
        createEmployeeRegularInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeeRegularInvite = gql`
    mutation UpdateEmployeeRegularInvite($input: UpdateEmployeeRegularInviteInput!) {
        updateEmployeeRegularInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const createEmployeePlusInvite = gql`
    mutation CreateEmployeePlusInvite($input: CreateEmployeePlusInviteInput!) {
        createEmployeePlusInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeePlusInvite = gql`
    mutation UpdateEmployeePlusInvite($input: UpdateEmployeePlusInviteInput!) {
        updateEmployeePlusInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const createEmployeeManagerInvite = gql`
    mutation CreateEmployeeManagerInvite($input: CreateEmployeeManagerInviteInput!) {
        createEmployeeManagerInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeeManagerInvite = gql`
    mutation UpdateEmployeeManagerInvite($input: UpdateEmployeeManagerInviteInput!) {
        updateEmployeeManagerInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const createEmployeeVolunteerInvite = gql`
    mutation CreateEmployeeVolunteerInvite($input: CreateEmployeeVolunteerInviteInput!) {
        createEmployeeVolunteerInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeeVolunteerInvite = gql`
    mutation UpdateEmployeeVolunteerInvite($input: UpdateEmployeeVolunteerInviteInput!) {
        updateEmployeeVolunteerInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const createEmployeePrInvite = gql`
    mutation CreateEmployeePrInvite($input: CreateEmployeePrInviteInput!) {
        createEmployeePrInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const updateEmployeePrInvite = gql`
    mutation UpdateEmployeePrInvite($input: UpdateEmployeePrInviteInput!) {
        updateEmployeePrInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
export const deleteEmployeeInvite = gql`
    mutation DeleteEmployeeInvite($input: DeleteEmployeeInviteInput!) {
        deleteEmployeeInvite(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
