import { gql } from '@urql/core'
import { FileFragment, ImageFragment, VideoFragment } from './posts'
import { AuthorFragment, SimpleRoleFragment } from './user'

const DivisionPostFragment = gql`
    ${ImageFragment}
    ${FileFragment}
    ${VideoFragment}
    ${AuthorFragment}
    ${SimpleRoleFragment}
    fragment DivisionPost on DivisionPostNode {
        id
        body
        bodyHtml
        created
        video {
            ...Video
        }
        images {
            edges {
                node {
                    ...Image
                }
            }
        }
        files {
            edges {
                node {
                    ...File
                }
            }
        }
        likes {
            totalCount
            edges {
                node {
                    ...Role
                }
            }
        }
        author {
            ...Author
        }
        rights {
            update
            delete
            createComment
            createVideo
        }
    }
`

export const divisionForums = gql`
    ${AuthorFragment}
    query divisionForums($forumIds: [ID]!) {
        divisionForums(forumIds: $forumIds) {
            edges {
                node {
                    id
                    division {
                        id
                        name
                    }
                    threads(first: 3) {
                        totalCount
                        edges {
                            node {
                                id
                                title
                                commentCount
                                firstPost {
                                    id
                                    body
                                    created
                                    author {
                                        ...Author
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const divisionForumQuery = gql`
    ${AuthorFragment}
    query divisionForum($forumId: ID!) {
        node(id: $forumId) {
            ... on DivisionForumNode {
                id
                division {
                    id
                }
                threads {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasPreviousPage
                        hasNextPage
                    }
                    edges {
                        node {
                            id
                            title
                            commentCount
                            firstPost {
                                id
                                body
                                created
                                author {
                                    ...Author
                                }
                            }
                            comments(last: 1) {
                                edges {
                                    node {
                                        id
                                        body
                                        created
                                        author {
                                            ...Author
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const createDivisionThread = gql`
    ${DivisionPostFragment}
    mutation createDivisionThread($input: CreateDivisionThreadInput!) {
        createDivisionThread(input: $input) {
            errors {
                field
                messages
            }
            thread {
                id
                firstPost {
                    ...DivisionPost
                    thread
                }
            }
        }
    }
`

export const createDivisionReply = gql`
    ${ImageFragment}
    mutation createDivisionReply($input: CreateDivisionReplyInput!) {
        createDivisionReply(input: $input) {
            errors {
                field
                messages
            }
            post {
                id
                thread
                images {
                    edges {
                        node {
                            ...Image
                        }
                    }
                }
            }
        }
    }
`

export const divisionThread = gql`
    ${DivisionPostFragment}
    ${AuthorFragment}
    query divisionThread(
        $threadId: ID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $reverse: Boolean
    ) {
        node(id: $threadId) {
            ... on DivisionThreadNode {
                id
                title
                commentCount
                isLocked
                title
                author {
                    ...Author
                }
                firstPost {
                    ...DivisionPost
                }
                comments(
                    first: $first
                    last: $last
                    before: $before
                    after: $after
                    reverse: $reverse
                ) {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasPreviousPage
                        hasNextPage
                    }
                    edges {
                        node {
                            ...DivisionPost
                        }
                    }
                }
            }
        }
    }
`

export const divisionsQuery = gql`
    query Divisions($ids: [ID]) {
        divisions(ids: $ids) {
            edges {
                node {
                    id
                    name
                    slug
                    forum
                    coverPhoto
                    rights {
                        createPost
                    }
                }
            }
        }
    }
`

export const updateDivisionPost = gql`
    ${DivisionPostFragment}
    mutation updateDivisionPost($input: UpdateDivisionPostInput!) {
        updateDivisionPost(input: $input) {
            errors {
                field
                messages
            }
            post {
                ...DivisionPost
            }
        }
    }
`

export const deleteDivisionPost = gql`
    mutation deleteDivisionPost($input: DeleteDivisionPostInput!) {
        deleteDivisionPost(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateDivisionThread = gql`
    mutation updateDivisionThread($input: UpdateDivisionThreadInput!) {
        updateDivisionThread(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateDivision = gql`
    mutation updateDivision($input: UpdateDivisionInput!) {
        updateDivision(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const createDivision = gql`
    mutation createDivision($input: CreateDivisionInput!) {
        createDivision(input: $input) {
            errors {
                field
                messages
            }
            id
        }
    }
`

export const deleteDivisionMutation = gql`
    mutation DeleteDivision($input: DeleteDivisionInput!) {
        deleteDivision(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateDivisionCoverPhotoMutation = gql`
    mutation UpdateDivisionCoverPhoto($input: UpdateDivisionCoverPhotoInput!) {
        updateDivisionCoverPhoto(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateDivisionPostImageTitle = gql`
    mutation updateDivisionPostImageTitle($input: UpdateDivisionPostImageTitleInput!) {
        updateDivisionPostImageTitle(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
