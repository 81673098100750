import { useEffect, useState } from 'react'

export const useIsMobileDevice = () => /Android|iPhone|iPad/i.test(navigator.userAgent)

export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(false)

    useEffect(() => {
        const mediaQuery = window.matchMedia(query)

        setMatches(mediaQuery.matches)

        const handler = (event) => setMatches(event.matches)

        mediaQuery.addListener(handler)

        return () => mediaQuery.removeListener(handler)
    }, [query])

    return matches
}

export const useIsMobile = () => {
    return useMediaQuery(`(max-width: 767px)`)
}

export const useIsSmall = () => {
    return useMediaQuery(`(min-width: 768px) and (max-width: 1023px)`)
}

export const useIsMedium = () => {
    return useMediaQuery(`(min-width: 1024px) and (max-width: 1279px)`)
}
