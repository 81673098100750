export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
    [_ in K]?: never
}
export type Incremental<T> =
    | T
    | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    Map: { input: any; output: any }
    Time: { input: any; output: any }
    Void: { input: any; output: any }
}

export type AcknowledgeRoleEventGroupsUpdateInput = {
    groupIds: Array<Scalars['ID']['input']>
}

export type Count = {
    __typename?: 'Count'
    count: Scalars['Int']['output']
    etag: Scalars['String']['output']
    timestamp: Scalars['Time']['output']
}

export type Error = {
    __typename?: 'Error'
    field?: Maybe<Scalars['String']['output']>
    msg: Scalars['String']['output']
}

export type Event = {
    __typename?: 'Event'
    id: Scalars['String']['output']
    vars: Scalars['Map']['output']
    when: Scalars['Time']['output']
}

export type EventGroup = Node & {
    __typename?: 'EventGroup'
    events: Array<Event>
    id: Scalars['ID']['output']
    msg: Scalars['String']['output']
    msgType: MsgType
    path: Scalars['String']['output']
    read: Scalars['Boolean']['output']
    refVars: Scalars['Map']['output']
    reference: Scalars['String']['output']
    role: Scalars['ID']['output']
    when: Scalars['Time']['output']
    who: Array<Scalars['String']['output']>
}

export type EventGroupPage = {
    __typename?: 'EventGroupPage'
    nodes: Array<EventGroup>
    pageInfo: PageInfo
}

export enum Locale {
    En = 'EN',
    Nl = 'NL',
}

export type MarkEventsReadInput = {
    groupIds: Array<Scalars['ID']['input']>
}

export enum MsgType {
    CalClientNew = 'CAL_CLIENT_NEW',
    CalLocationNew = 'CAL_LOCATION_NEW',
    ClientArchived = 'CLIENT_ARCHIVED',
    ClientDeleted = 'CLIENT_DELETED',
    ClientExportReady = 'CLIENT_EXPORT_READY',
    ClientLikeReplyYou = 'CLIENT_LIKE_REPLY_YOU',
    ClientLikeYou = 'CLIENT_LIKE_YOU',
    ClientMsgEmployee = 'CLIENT_MSG_EMPLOYEE',
    ClientMsgFamily = 'CLIENT_MSG_FAMILY',
    ClientNew = 'CLIENT_NEW',
    ClientReply = 'CLIENT_REPLY',
    ClientReplyYou = 'CLIENT_REPLY_YOU',
    ClientUnarchived = 'CLIENT_UNARCHIVED',
    ForumLikeReplyYou = 'FORUM_LIKE_REPLY_YOU',
    ForumLikeYou = 'FORUM_LIKE_YOU',
    ForumMsg = 'FORUM_MSG',
    ForumReplyYou = 'FORUM_REPLY_YOU',
    GrpLikeYou = 'GRP_LIKE_YOU',
    GrpMsg = 'GRP_MSG',
    GrpReplyYou = 'GRP_REPLY_YOU',
    InvEmployeeAccept = 'INV_EMPLOYEE_ACCEPT',
    InvEmployeeAcceptYou = 'INV_EMPLOYEE_ACCEPT_YOU',
    InvFamilyAccept = 'INV_FAMILY_ACCEPT',
    InvFamilyAcceptYou = 'INV_FAMILY_ACCEPT_YOU',
    LbBioAdded = 'LB_BIO_ADDED',
    LbEntryAdded = 'LB_ENTRY_ADDED',
    LbFriendAdded = 'LB_FRIEND_ADDED',
    LbHobbyAdded = 'LB_HOBBY_ADDED',
    LbMusicAdded = 'LB_MUSIC_ADDED',
    LbPhotoAdded = 'LB_PHOTO_ADDED',
    RoleFamilyDeleted = 'ROLE_FAMILY_DELETED',
    RoleTypeUpdatedYou = 'ROLE_TYPE_UPDATED_YOU',
    TestEvent = 'TEST_EVENT',
}

export type Mutation = {
    __typename?: 'Mutation'
    acknowledgeRoleEventGroupsUpdate?: Maybe<Scalars['Void']['output']>
    markEventsRead?: Maybe<Scalars['Void']['output']>
    setNotificationSettings: SetNotificationSettingsPayload
}

export type MutationAcknowledgeRoleEventGroupsUpdateArgs = {
    input: AcknowledgeRoleEventGroupsUpdateInput
}

export type MutationMarkEventsReadArgs = {
    input: MarkEventsReadInput
}

export type MutationSetNotificationSettingsArgs = {
    input: SetNotificationSettingsInput
}

export type Node = {
    id: Scalars['ID']['output']
}

export enum NotificationFormat {
    Compact = 'COMPACT',
    Extended = 'EXTENDED',
}

export type NotificationSettings = {
    __typename?: 'NotificationSettings'
    disabledMsgTypes: Array<Scalars['String']['output']>
    notification: NotificationType
    notificationFormat: NotificationFormat
}

export enum NotificationType {
    Daily = 'DAILY',
    Immediate = 'IMMEDIATE',
    Off = 'OFF',
    Weekly = 'WEEKLY',
}

export type PageInfo = {
    __typename?: 'PageInfo'
    endCursor?: Maybe<Scalars['String']['output']>
    hasNextPage: Scalars['Boolean']['output']
}

export type Query = {
    __typename?: 'Query'
    eventGroupsPage: EventGroupPage
    notificationSettings?: Maybe<NotificationSettings>
    unreadEventGroupCount?: Maybe<Scalars['Int']['output']>
}

export type QueryEventGroupsPageArgs = {
    cursor?: InputMaybe<Scalars['String']['input']>
    grouped?: InputMaybe<Scalars['Boolean']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    read?: InputMaybe<Scalars['Boolean']['input']>
}

export type RoleEventGroupsUpdate = {
    __typename?: 'RoleEventGroupsUpdate'
    eventGroups: Array<EventGroup>
    timestamp: Scalars['Time']['output']
}

export type SetNotificationSettingsInput = {
    disabledMsgTypes: Array<Scalars['String']['input']>
    notification?: InputMaybe<NotificationType>
    notificationFormat?: InputMaybe<NotificationFormat>
}

export type SetNotificationSettingsPayload = {
    __typename?: 'SetNotificationSettingsPayload'
    disabledMsgTypes: Array<Scalars['String']['output']>
    notification: NotificationType
    notificationFormat: NotificationFormat
}

/**
 * `Subscription` is where all the subscriptions your clients can
 * request. You can use Schema Directives like normal to restrict
 * access.
 */
export type Subscription = {
    __typename?: 'Subscription'
    /** `roleEventGroupsUpdate` will return a stream of updated role event groups */
    roleEventGroupsUpdate: RoleEventGroupsUpdate
    /** `unread` will return a stream of `Count` objects. */
    unread: Count
}

/**
 * `Subscription` is where all the subscriptions your clients can
 * request. You can use Schema Directives like normal to restrict
 * access.
 */
export type SubscriptionRoleEventGroupsUpdateArgs = {
    token: Scalars['String']['input']
}

/**
 * `Subscription` is where all the subscriptions your clients can
 * request. You can use Schema Directives like normal to restrict
 * access.
 */
export type SubscriptionUnreadArgs = {
    token: Scalars['String']['input']
}
