import * as React from 'react'
import onClickOutside from 'react-onclickoutside'

class OutsideClickHandler extends React.Component {
    handleClickOutside = (event) => this.props.onClickOutside(event)
    render = () => {
        const { children } = this.props
        if (React.Children.count(children) > 1) {
            throw new Error('click outside should handle exactly one container')
        }
        return children
    }
}

OutsideClickHandler.defaultProps = {
    onClickOutside: () => {},
}

const ClickOutside = onClickOutside(OutsideClickHandler)

export default ClickOutside
