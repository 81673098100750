import { NotificationProps } from '.'
import { format, parseISO } from 'date-fns'
import { MsgType } from '../../graphql/notifs'
import { dateFormat } from '../../helpers/date'
import { Event as NotifEvent } from '../../graphql/notifs'
import { authorizedClient } from '../../graphql/clients'
import { clientQuery } from '../../graphql/queries/client'
import { ClientNode, DivisionNode } from '../../graphql/types'
import { divisionsQuery } from '../../graphql/queries/division'

/* Extract the first available value from the event vars */
export const firstEventVar = (events: Array<NotifEvent>, key: string): any => {
    return events.map((e) => e.vars?.[key]).find((e) => !!e)
}

export const retrieveClient = async (clientId: string): Promise<ClientNode | null> => {
    const { client }: { client: ClientNode } = await authorizedClient
        .query(clientQuery, { id: clientId })
        .toPromise()
        .then((res) => res.data)
    return client ?? null
}

export const retrieveDivision = async (divisionId: string): Promise<DivisionNode | null> => {
    const { divisions }: { divisions?: { edges: { node: DivisionNode }[] } } =
        await authorizedClient
            .query(divisionsQuery, {})
            .toPromise()
            .then((res) => res.data)

    console.log('divisions', divisions, divisionId)

    return (
        divisions?.edges?.find((divisionEdge) => divisionId && divisionEdge.node.id === divisionId)
            ?.node ?? null
    )
}

export const notificationLinkBuilder = async (props: NotificationProps): Promise<string> => {
    const { msgType, refVars, events } = props

    // Handle invite notifications
    if (msgType.startsWith('INV_')) {
        if (refVars?.type?.startsWith('employee')) {
            return `/employees`
        }
        if (refVars.clientId) {
            return `/client/${refVars.clientId}/members`
        }
        return '/notifications'
    }

    // Handle group messages
    if (msgType.startsWith('GRP_')) {
        const newPost = events.map(({ vars }) => vars.clientPostId).join(',')
        const newLike = events.map(({ vars }) => vars.postId).join(',')
        const threadIds = [
            ...new Set(
                events
                    .map((e) => e.vars?.threadId)
                    .filter((e) => !!e)
                    .reverse()
            ),
        ]

        if (msgType === MsgType.GrpReplyYou) {
            return `/group-message/thread/${refVars.threadId}?newPost=${newPost}`
        }
        if (msgType === MsgType.GrpLikeYou) {
            return `/group-message/thread/${refVars.threadId}?newLike=${newLike}`
        }
        if (msgType === MsgType.GrpMsg) {
            if (threadIds.length > 1) {
                return `/group-message/threads/${threadIds.join(',')}?newThread=${threadIds.join(',')}`
            }
            return `/group-message/thread/${refVars.threadId}?newThread=${refVars.threadId}`
        }
        return '/group-message'
    }

    // Handle forum notifications
    if (msgType.startsWith('FORUM_')) {
        const division = await retrieveDivision(refVars.divisionId)
        const base = division ? `/forum/${division.forum}` : '/forum'
        const newPost = events.map(({ vars }) => vars.postId).join(',')

        if (msgType === MsgType.ForumMsg) {
            return `${base}/${refVars.threadId}?newThread=${refVars.threadId}`
        }
        if (msgType === MsgType.ForumReplyYou) {
            return `${base}/${refVars.threadId}?newPost=${newPost}`
        }
        if (msgType === MsgType.ForumLikeYou) {
            return `${base}/${refVars.threadId}?newLike=${newPost}`
        }
        if (msgType === MsgType.ForumLikeReplyYou) {
            return `${base}/${refVars.threadId}?newLike=${refVars.postId || newPost}`
        }
        return refVars.threadId ? `${base}/${refVars.threadId}` : base
    }

    // Handle client notifications
    if (msgType.startsWith('CLIENT_')) {
        const client = await retrieveClient(refVars.clientId)

        const threadIds = [...new Set(events.map((e) => e.vars?.threadId).filter(Boolean))].join(
            ','
        )
        const postIds = [...new Set(events.map((e) => e.vars?.postId).filter(Boolean))].join(',')

        let highlight = ''
        if (msgType === MsgType.ClientReply || msgType === MsgType.ClientReplyYou) {
            highlight = `?newPost=${postIds}`
        } else if (msgType === MsgType.ClientMsgFamily || msgType === MsgType.ClientMsgEmployee) {
            highlight = `?newThread=${threadIds}`
        } else if (msgType === MsgType.ClientLikeYou || msgType === MsgType.ClientLikeReplyYou) {
            highlight = `?newLike=${postIds}`
        }

        if (client && threadIds) {
            return `/client/${client.slug}/threads/${threadIds}${highlight}`
        }
        if (client && refVars.threadId) {
            return `/client/${client.slug}/thread/${refVars.threadId}${highlight}`
        }
        return '/notifications'
    }

    // Handle lifebook notifications
    if (msgType.startsWith('LB_')) {
        const client = await retrieveClient(refVars.clientId)

        let page = ''
        if (msgType.includes('MUSIC')) page = '/music'
        if (msgType.includes('FRIEND')) page = '/important-persons'
        if (msgType.includes('ENTRY')) page = '/timeline'
        if (msgType.includes('HOBBY')) page = '/hobbies'

        if (client) {
            return `/client/${client.slug}/lifebook${page}`
        }
        return '/notifications'
    }

    // Handle calendar notifications
    if (msgType.startsWith('CAL_')) {
        let clientId: string = firstEventVar(events, 'clientId')
        const client = await retrieveClient(clientId)

        const day = format(parseISO(refVars.start), dateFormat)
        if (client) {
            return `/client/${client.slug}/agenda/day/${day}`
        }
        return `/calendar/day/${day}`
    }

    // Default fallback
    return '/notifications'
}
