import qs from 'query-string'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import { useScrollToNew } from '../hooks/scroll'

type SearchQuery = Record<string, string | null>

interface SimpleLocation {
    pathname: string
    search: string
    hash: string
}

interface RouteContextInterface {
    location: Location
    query: SearchQuery
    pathname: string
    hash?: string
    changeQuery(query: SearchQuery): SimpleLocation
    pushQuery(query: SearchQuery): void
    replaceQuery(query: SearchQuery): void
    changeHash(hash: string | null): SimpleLocation
}

export const RouteContext = React.createContext<RouteContextInterface>({} as RouteContextInterface)
export default RouteContext

export const useQuery = () => {
    const location = useLocation()
    return useMemo(() => qs.parse(location.search) as SearchQuery, [location.search])
}

export const useHash = () => useContext(RouteContext)
export const useRouteContext = () => useContext(RouteContext)

export const RouteProvider = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()
    const prevPathname = React.useRef<Location['pathname']>()

    // this returns new route with a merged query
    // it is not an event
    const changeQuery = useCallback(
        (query) => {
            const { search, pathname, hash } = location

            const searchQuery = {
                ...qs.parse(search),
                ...query,
            }

            Object.keys(searchQuery).forEach((key) => {
                if (searchQuery[key] === null || searchQuery[key] === '') {
                    delete searchQuery[key]
                }
            })

            return {
                pathname,
                search: qs.stringify(searchQuery),
                hash,
            }
        },
        [location]
    )

    const replaceQuery = useCallback(
        (query) => {
            const newQuery = changeQuery(query)
            navigate(newQuery, { replace: true })
        },
        [changeQuery, navigate]
    )

    const pushQuery = useCallback(
        (query) => {
            const newQuery = changeQuery(query)
            navigate(newQuery)
        },
        [changeQuery, navigate]
    )

    const changeHash = useCallback(
        (hash) => {
            const { search, pathname } = location
            return {
                pathname,
                search,
                hash,
            }
        },
        [location]
    )

    // scroll to top on route change
    useEffect(() => {
        if (location.hash) return // of location has a hash, don't scroll to top
        if (
            (prevPathname.current?.includes('month') && location.pathname.includes('month')) ||
            (prevPathname.current?.includes('day') && location.pathname.includes('day'))
        ) {
            prevPathname.current = location.pathname
            return
        }
        document.body.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior })
        prevPathname.current = location.pathname
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, location.search])

    // scroll to new items
    useScrollToNew(location.search)

    return (
        <RouteContext.Provider
            value={{
                location,
                query,
                pathname: location.pathname,
                hash: location.hash?.replace('#', '') || undefined,
                changeQuery,
                changeHash,
                replaceQuery,
                pushQuery,
            }}
        >
            {children}
        </RouteContext.Provider>
    )
}
