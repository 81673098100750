import { useEffect, useMemo, useState } from 'react'

import { QueryResult, ReloadQuery, useToastyQuery } from '.'
import { useRoleContext } from '../../context/role'
import { useUserContext } from '../../context/user'
import { employeeInvites } from '../../graphql/queries/invites'
import { employeesQuery } from '../../graphql/queries/location'
import { allRoles } from '../../graphql/queries/me'
import { getEmployeeBySlug, getRole } from '../../graphql/queries/user'
import {
    ClientNode,
    EmployeeInviteNode,
    LocationNode,
    LocationRights,
    Maybe,
    RoleNode,
    UserNode,
} from '../../graphql/types'
import { t } from '../../helpers/i18n'
import { FieldOption } from '../../types'

export const useActiveRole = (): RoleNode | undefined => {
    const { role } = useRoleContext()
    return role
}

export const useActiveUser = (): UserNode | undefined => {
    const { user } = useUserContext()
    return user
}

export const useRoles = (props?: { pause?: boolean }): [RoleNode[], QueryResult, ReloadQuery] => {
    const [result, reload] = useToastyQuery({
        query: allRoles,
        pause: props?.pause,
    })

    const roles: RoleNode[] = useMemo(
        () => result.data?.allRoles?.edges.map(({ node }) => node) || [],
        [result.data]
    )

    return useMemo(() => [roles, result, reload], [roles, result, reload])
}

export const useRoleClient = (): ClientNode | undefined => {
    const role = useActiveRole()
    return role?.clients?.edges?.[0]?.node || undefined
}

export const useOrgLocation = (): LocationNode | undefined => {
    const role = useActiveRole()
    return role?.location || undefined
}

export const useLocationRights = (): LocationRights | undefined => {
    const role = useActiveRole()
    return role?.location?.rights || undefined
}

export const useEmployeeCreateOptions = () => {
    const rights = useLocationRights()

    const employeeCreateOptions: FieldOption[] = []

    if (rights?.createEmployeeManager) {
        employeeCreateOptions.push({
            value: 'EMPLOYEE_MANAGER',
            label: t('employee_manager'),
        })
    }

    if (rights?.createEmployeePlus) {
        employeeCreateOptions.push({
            value: 'EMPLOYEE_PLUS',
            label: t('employee_plus'),
        })
    }

    if (rights?.createEmployeeRegular) {
        employeeCreateOptions.push({
            value: 'EMPLOYEE_REGULAR',
            label: t('employee_regular'),
        })
    }

    if (rights?.createEmployeePr) {
        employeeCreateOptions.push({
            value: 'EMPLOYEE_PR',
            label: t('employee_pr'),
        })
    }

    if (rights?.createEmployeeVolunteer) {
        employeeCreateOptions.push({
            value: 'EMPLOYEE_VOLUNTEER',
            label: t('employee_volunteer'),
        })
    }

    return employeeCreateOptions
}

export const useForumId = () => {
    const role = useActiveRole()
    return useMemo(() => role?.location?.forum, [role])
}

export const useRole = (id?: string): Maybe<RoleNode> => {
    const [result] = useToastyQuery({
        query: getRole,
        pause: !id,
        variables: {
            id,
        },
    })
    return result.data?.node
}

export const useEmployeeBySlug = (slug?: string | undefined): Maybe<RoleNode> => {
    const [result] = useToastyQuery({
        query: getEmployeeBySlug,
        pause: !slug,
        variables: {
            slug,
        },
    })
    return result.data?.employeeBySlug
}

interface SearchProps {
    search?: string
}

export const useAllEmployees = (props?: SearchProps): [RoleNode[], boolean, Error | null] => {
    const role = useActiveRole()
    const [employees, setEmployees] = useState<RoleNode[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error | null>(null)
    const [after, setAfter] = useState<string | null>(null)

    const [result] = useToastyQuery({
        query: employeesQuery,
        variables: {
            location: role?.location?.id,
            first: 400,
            after,
            ...props,
        },
        pause: !role?.location?.id,
    })

    useEffect(() => {
        if (result.data?.employees?.edges) {
            setEmployees((prevEmployees) => [
                ...prevEmployees.map((employee) => ({
                    ...employee,
                    ...result.data.employees.edges.find(({ node }) => node.id === employee.id)
                        ?.node, // Merge with existing data
                })),
                ...result.data.employees.edges
                    .map(({ node }) => node)
                    .filter(
                        (node) =>
                            !prevEmployees.find((employee) => employee.id === node.id) && !!node
                    ),
            ])
            setLoading(false)
            if (result.data.employees.pageInfo.hasNextPage) {
                setAfter(result.data.employees.pageInfo.endCursor)
            }
        }

        if (result.error) {
            setError(result.error)
            setLoading(false)
        }
    }, [result.data, result.error])

    useEffect(() => {
        if (role?.location?.id) {
            setLoading(true)
            setEmployees([])
            setAfter(null) // Reset pagination cursor when location changes
        }
    }, [role?.location?.id])

    return [employees, loading, error]
}

export const useEmployees = (props?: SearchProps): [RoleNode[], QueryResult] => {
    const role = useActiveRole()

    const [result] = useToastyQuery({
        query: employeesQuery,
        variables: {
            location: role?.location?.id,
            ...props,
        },
        pause: !role?.location?.id,
    })

    const employees = result.data?.employees?.edges.map(({ node }) => node) || []

    return [employees, result]
}

export const useEmployeeInvites = (props?: SearchProps): [EmployeeInviteNode[], QueryResult] => {
    const role = useActiveRole()

    const [result] = useToastyQuery({
        query: employeeInvites,
        variables: {
            location: role?.location?.id,
            ...props,
        },
        pause: !role?.location?.id,
    })

    const invites = result.data?.employeeInvites.edges.map(({ node }) => node) || []

    return [invites, result]
}
