export function scrollToTargetTop(el: Element | string, offset = 30, behavior = 'smooth') {
    if (typeof el === 'string') {
        const element = document.querySelector(el) as Element
        if (!element) {
            setTimeout(() => {
                scrollToTargetTop(el)
            }, 100)
            return
        }
        el = element
    }
    var wrapper = document.querySelector('body')
    var wrapperOffset = wrapper?.scrollTop || 0
    var header = document.querySelector('nav')
    var headerOffset = header?.getBoundingClientRect().height || 0
    var elementPosition = el.getBoundingClientRect().top
    var offsetPosition = elementPosition + wrapperOffset - headerOffset - offset

    setTimeout(() => {
        document.body.scrollTo({
            left: 0,
            top: offsetPosition,
            behavior: behavior as ScrollBehavior,
        })
    }, 100)
}
