import {
    differenceInMonths,
    format,
    formatDistance,
    FormatDistanceOptions,
    isSameDay,
    parseISO,
} from 'date-fns'
import { da, de, enGB, fr, nb, nl } from 'date-fns/locale'
import { DateTime } from '../types'

import { capitalize, polyglot, t } from './i18n'

const getLocale = () => {
    if (polyglot.locale().includes('nl')) return nl
    if (polyglot.locale().includes('fr')) return fr
    if (polyglot.locale().includes('fr')) return de
    if (polyglot.locale().includes('da')) return da
    if (polyglot.locale().includes('nb')) return nb
    return enGB
}

export const dateFormat = 'yyyy-MM-dd'
export const monthFormat = 'yyyy-MM'
export const timeFormat = 'HH:mm'

export const ago = (date: Date | DateTime, options?: FormatDistanceOptions) => {
    if (date === '') return t('never')
    const parsedDate = typeof date === 'string' ? parseISO(date) : new Date()
    return formatDistance(parsedDate, new Date(), {
        locale: getLocale(),
        addSuffix: true,
        ...options,
    })
}

const autoFormat = (date: Date | DateTime, f: string, cap: boolean = false): string => {
    /* Format a date or iso-timestamp string 
    
    With: 
        date -- the date or datetime string to format
        f -- the formatting string
        cap -- capitalize the first char of the resulting string
    
    Returns the formatted date as a string
    */
    const parsedDate = typeof date === 'string' ? parseISO(date) : date
    const s = format(parsedDate, f, { locale: getLocale() })
    return cap ? capitalize(s) : s
}

export const formatWeekday = (date: Date | DateTime) => {
    return autoFormat(date, 'EEEEEE')
}

export const formatMonthYear = (date: Date | DateTime) => {
    return autoFormat(date, 'MMMM yyyy', true)
}

export const formatMonth = (date: Date | DateTime) => {
    return autoFormat(date, 'MMMM', true)
}

export const formatYear = (date: Date | DateTime) => {
    return autoFormat(date, 'yyyy')
}

export const formatDay = (date: Date | DateTime) => {
    return autoFormat(date, 'EEEE d LLLL', true)
}

export const formatDate = (date: Date | DateTime) => {
    return autoFormat(date, 'd LLLL yyyy', true)
}

export const formatDateTime = (date: Date | DateTime) => {
    return autoFormat(date, 'd LLLL yyyy HH:mm', true)
}

export const formatUrlDate = (date: Date | DateTime) => {
    return autoFormat(date, dateFormat)
}

export const formatGraphqlDate = (date: Date | DateTime) => {
    return autoFormat(date, dateFormat)
}

export const formatDayDate = (date: Date | DateTime) => {
    return autoFormat(date, 'EEEE d LLLL yyyy', true)
}

export const formatTime = (date: Date | DateTime) => {
    return autoFormat(date, 'HH:mm')
}

export const dayAtTimeOrAgo = (date) => {
    const parsedDate = parseISO(date)
    const isToday = isSameDay(parsedDate, new Date())
    return isToday ? ago(date) : dayAtTime(date)
}

export const timeToTime = (startDate, endDate) => {
    return t('%{startTime} until %{endTime}', {
        startTime: formatTime(startDate),
        endTime: formatTime(endDate),
    })
}

export const dayAtTime = (date) => {
    const parsedDate = parseISO(date)
    const isToday = isSameDay(parsedDate, new Date())

    // is date more than 6 months ago?
    const isOld = differenceInMonths(new Date(), parsedDate) > 6

    return t('%{day} at %{time}', {
        day: isToday
            ? t('Today')
            : capitalize(
                  format(parsedDate, isOld ? 'EEEE d LLLL yyyy' : 'EEEE d LLLL', {
                      locale: getLocale(),
                  })
              ),
        time: formatTime(date),
    })
}

export const dayAtTimeToTime = (startDate, endDate) => {
    const parsedStartDate = parseISO(startDate)
    const parsedEndDate = parseISO(endDate)
    const isDifferentDay = !isSameDay(parsedStartDate, parsedEndDate)
    if (isDifferentDay) {
        return dayAtTimeToDayAtTime(startDate, endDate)
    }

    // Check if start is today
    const isToday = isSameDay(parsedStartDate, new Date())

    // is date more than 6 months ago?
    const isOld = differenceInMonths(new Date(), parsedStartDate) > 6

    return t('%{day} at %{startTime} until %{endTime}', {
        day: isToday
            ? t('Today')
            : capitalize(
                  format(parsedStartDate, isOld ? 'EEEE d LLLL yyyy' : 'EEEE d LLLL', {
                      locale: getLocale(),
                  })
              ),
        startTime: formatTime(startDate),
        endTime: formatTime(endDate),
    })
}

export const dayAtTimeToDayAtTime = (startDate, endDate) => {
    const parsedStartDate = parseISO(startDate)
    const parsedEndDate = parseISO(endDate)
    if (isSameDay(parsedStartDate, parsedEndDate)) {
        return dayAtTimeToTime(startDate, endDate)
    }

    // Check if start is today
    const isToday = isSameDay(parsedStartDate, new Date())

    // is date more than 6 months ago?
    const isOld = differenceInMonths(new Date(), parsedStartDate) > 6

    return t('%{startDay} at %{startTime} until %{endDay} at %{endTime}', {
        startDay: isToday
            ? t('Today')
            : capitalize(
                  format(parsedStartDate, isOld ? 'EEEE d LLLL yyyy' : 'EEEE d LLLL', {
                      locale: getLocale(),
                  })
              ),
        endDay: isToday
            ? t('Today')
            : capitalize(
                  format(parsedEndDate, isOld ? 'EEEE d LLLL yyyy' : 'EEEE d LLLL', {
                      locale: getLocale(),
                  })
              ),
        startTime: formatTime(startDate),
        endTime: formatTime(endDate),
    })
}

export const dayAllDay = (date) => {
    const parsedDate = parseISO(date)
    const isToday = isSameDay(parsedDate, new Date())
    return t('%{day} all day', {
        day: isToday
            ? t('Today')
            : capitalize(format(parsedDate, 'EEEE d LLLL', { locale: getLocale() })),
    })
}
