import { gql } from '@urql/core'

export const accessTokenQuery = gql`
    query AccessToken {
        accessToken
    }
`

export const notificationsQuery = gql`
    query Events($limit: Int, $cursor: String, $read: Boolean, $grouped: Boolean) {
        eventGroupsPage(limit: $limit, cursor: $cursor, read: $read, grouped: $grouped) {
            pageInfo {
                hasNextPage
                endCursor
            }
            nodes {
                id
                role
                path
                reference
                msgType
                msg
                when
                who
                refVars
                read
                events {
                    id
                    vars
                    when
                }
            }
        }
    }
`

export const markEventsReadMutation = gql`
    mutation MarkEventsRead($input: MarkEventsReadInput!) {
        markEventsRead(input: $input)
    }
`

export const notificationSettingsQuery = gql`
    query NotificationSettings {
        notificationSettings {
            notification
            notificationFormat
            disabledMsgTypes
            notificationFormat
        }
    }
`

export const notificationSettingsMutation = gql`
    mutation SetNotificationSettings($input: SetNotificationSettingsInput!) {
        setNotificationSettings(input: $input) {
            notification
        }
    }
`

export const unread = gql`
    subscription unread($token: String!) {
        unread(token: $token) {
            timestamp
        }
    }
`
