import { Link } from 'react-router-dom'

import { useRoles } from '../../../hooks/data/user'

import Trans from '../../../components/trans'
import { t } from '../../../helpers/i18n'

import { ReactComponent as Account } from '../../../../svg/account.svg'
import { ReactComponent as Exit } from '../../../../svg/exit.svg'
import { ReactComponent as Help } from '../../../../svg/help.svg'
import { ReactComponent as Switch } from '../../../../svg/switch.svg'

interface Props {
    onClick: () => void
}

const PersonalMenu = ({ onClick }: Props) => {
    const [roles] = useRoles()
    return (
        <ul className="main-menu">
            <li>
                <a
                    href={t('https://www.familynet.co.uk/help/employee/')}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Help />
                    <Trans label="Help" />
                </a>
            </li>
            <li>
                <Link onClick={onClick} to="/account/settings">
                    <Account />
                    <Trans label="My account" />
                </Link>
            </li>
            {roles?.length > 1 && (
                <li>
                    <Link onClick={onClick} to="/account/pick-role">
                        <Switch />
                        <Trans label="Select role" />
                    </Link>
                </li>
            )}
            <li>
                <Link onClick={onClick} to="/logout">
                    <Exit />
                    <Trans label="Log out" />
                </Link>
            </li>
        </ul>
    )
}

export default PersonalMenu
