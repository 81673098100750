import { ToastOptions, toast } from 'react-toastify'

export const Message = ({ title, message, code }) => (
    <>
        <h5>{title}</h5>
        {message && <p>{message}</p>}
        {code && (
            <pre>
                <code>{code}</code>
            </pre>
        )}
    </>
)

export default class Notification {
    static info(notification, options?: ToastOptions) {
        toast.info(<Message {...notification} />, options)
    }
    static success(notification, options?: ToastOptions) {
        toast.success(<Message {...notification} />, options)
    }
    static warning(notification, options?: ToastOptions) {
        toast.warning(<Message {...notification} />, options)
    }
    static error(notification, options?: ToastOptions) {
        toast.error(<Message {...notification} />, options)
    }
}

export function unflatten(obj: { [key: string]: any }): { [key: string]: any } {
    const result: any = {}

    for (const flatKey in obj) {
        const value = obj[flatKey]

        // Split the key into parts, handling arrays
        const keys = flatKey.split('.').reduce((acc: string[], key) => {
            const arrayParts = key.split(/[[\]]+/).filter(Boolean)
            return acc.concat(arrayParts)
        }, [])

        let current = result
        let parent: any = null
        let parentKey: string | number | null = null

        for (let i = 0; i < keys.length; i++) {
            let keyPart: string | number = keys[i]
            const isArrayIndex = /^\d+$/.test(keyPart)

            if (isArrayIndex) {
                keyPart = parseInt(keyPart, 10)
            }

            if (i === keys.length - 1) {
                // Last key, set the value
                if (isArrayIndex) {
                    if (!Array.isArray(current)) {
                        current = []
                        if (parent !== null && parentKey !== null) {
                            parent[parentKey] = current
                        }
                    }
                    current[keyPart] = value
                } else {
                    current[keyPart] = value
                }
            } else {
                // Not the last key, prepare for next iteration
                if (isArrayIndex) {
                    if (!Array.isArray(current)) {
                        current = []
                        if (parent !== null && parentKey !== null) {
                            parent[parentKey] = current
                        }
                    }
                    if (!current[keyPart]) {
                        current[keyPart] = {}
                    }
                    parent = current
                    parentKey = keyPart
                    current = current[keyPart]
                } else {
                    if (!current[keyPart]) {
                        current[keyPart] = {}
                    }
                    parent = current
                    parentKey = keyPart
                    current = current[keyPart]
                }
            }
        }
    }

    return result
}
