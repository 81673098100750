import { Link } from 'react-router-dom'

import Trans from '../../../components/trans'
import { t } from '../../../helpers/i18n'

import { useActiveRole, useLocationRights, useRoles } from '../../../hooks/data/user'

import { useMemo } from 'react'
import { ReactComponent as Account } from '../../../../svg/account.svg'
import { ReactComponent as Bell } from '../../../../svg/bell.svg'
import { ReactComponent as Agenda } from '../../../../svg/calendar.svg'
import { ReactComponent as Cog } from '../../../../svg/cog.svg'
import { ReactComponent as Exit } from '../../../../svg/exit.svg'
import { ReactComponent as Forum } from '../../../../svg/forum.svg'
import { ReactComponent as Help } from '../../../../svg/help.svg'
import { ReactComponent as Organisation } from '../../../../svg/organisation.svg'
import { ReactComponent as People } from '../../../../svg/people.svg'
import { ReactComponent as Send } from '../../../../svg/send.svg'
import { ReactComponent as StatisticsIcon } from '../../../../svg/statistics.svg'
import { ReactComponent as Switch } from '../../../../svg/switch.svg'

import Debug from '../../../components/debug'
import TextOverflow from '../../../components/nowrap'
import { useNotificationStore } from '../../../context/notification'

interface Props {
    onClick: () => void
}

const EmployeeMenu = ({ onClick }: Props) => {
    const { unreadCount } = useNotificationStore()
    const rights = useLocationRights()

    const [roles] = useRoles()
    const role = useActiveRole()
    const isPR = useMemo(() => role?.type === 'EMPLOYEE_PR', [role])
    const isManager = useMemo(() => role?.type === 'EMPLOYEE_MANAGER', [role])

    const divisionsWithForum =
        role?.location?.divisions?.edges?.filter((edge) => edge?.node?.forum) || []

    const canCreateEmployee =
        rights?.createEmployeeManager ||
        rights?.createEmployeePlus ||
        rights?.createEmployeePr ||
        rights?.createEmployeeRegular ||
        rights?.createEmployeeVolunteer

    return (
        <>
            <ul className="main-menu">
                <li>
                    <Link onClick={onClick} to="/notifications">
                        <Bell />
                        <Trans label="Notifications" />
                        {!!unreadCount && <span className="badge">{unreadCount}</span>}
                    </Link>
                </li>
                {!isPR && (
                    <li>
                        <Link onClick={onClick} to="/clients">
                            <People />
                            <Trans label="Clients" />
                        </Link>
                    </li>
                )}
                <li>
                    <Link onClick={onClick} to={`/group-message`}>
                        <Send />
                        <Trans label="Group message" />
                    </Link>
                </li>
                <li>
                    <Link onClick={onClick} to="/calendar">
                        <Agenda />
                        <Trans label="Location agenda" />
                    </Link>
                </li>
                {!!divisionsWithForum.length && (
                    <li>
                        <Link onClick={onClick} to="/forum">
                            <Forum />
                            <Trans label="Forum" />
                        </Link>
                    </li>
                )}
                {role?.location && (
                    <li>
                        <Link onClick={onClick} to={`/location/${role.location.slug}`}>
                            <Organisation />
                            <TextOverflow maxWidth={250}>{`${role?.location.name}`}</TextOverflow>
                        </Link>
                    </li>
                )}
                {canCreateEmployee && (
                    <li>
                        <Link onClick={onClick} to="/employees">
                            <People />
                            <Trans label="Employees" />
                        </Link>
                    </li>
                )}
                {role?.location && (rights?.update || rights?.createDivision) && (
                    <li>
                        <Link onClick={onClick} to={`/location/${role?.location.slug}/settings`}>
                            <Cog />
                            <Trans label="Organisation admin" />
                        </Link>
                    </li>
                )}
                {isManager && (
                    <li>
                        <Link onClick={onClick} to={`/statistics`}>
                            <StatisticsIcon />
                            <Trans label="Statistics" />
                        </Link>
                    </li>
                )}
                <li>
                    <Link onClick={onClick} to="/account/settings">
                        <Account />
                        <Trans label="My account" />
                    </Link>
                </li>
                <li>
                    <a
                        href={t('https://www.familynet.co.uk/help/employee/')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Help />
                        <Trans label="Help" />
                    </a>
                </li>
                {roles?.length > 1 && (
                    <li>
                        <Link onClick={onClick} to="/account/pick-role">
                            <Switch />
                            <Trans label="Select role" />
                        </Link>
                    </li>
                )}
                <li>
                    <a onClick={onClick} href="/logout">
                        <Exit />
                        <Trans label="Log out" />
                    </a>
                </li>
            </ul>
            <Debug data={role?.location?.divisions} />
        </>
    )
}

export default EmployeeMenu
