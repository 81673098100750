import Figure from '../../../components/figure'
import TextOverflow from '../../../components/nowrap'
import { useActiveRole, useActiveUser } from '../../../hooks/data/user'

const MenuHeader = () => {
    const user = useActiveUser()
    const role = useActiveRole()

    return (
        <div className="menu-header">
            <Figure personIcon image={user?.photo} />
            <div>
                <strong>
                    {user?.firstName} {user?.lastName}
                </strong>
                {role && <TextOverflow maxWidth={600}>{`${role.label}`}</TextOverflow>}
            </div>
        </div>
    )
}

export default MenuHeader
