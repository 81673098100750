import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { t } from '../../../helpers/i18n'

import Trans from '../../../components/trans'
import RoleContext from '../../../context/role'

import { useOrgLocation, useRoleClient, useRoles } from '../../../hooks/data/user'

import { ReactComponent as Account } from '../../../../svg/account.svg'
import { ReactComponent as Bell } from '../../../../svg/bell.svg'
import { ReactComponent as Exit } from '../../../../svg/exit.svg'
import { ReactComponent as Help } from '../../../../svg/help.svg'
import { ReactComponent as Organisation } from '../../../../svg/organisation.svg'
import { ReactComponent as Switch } from '../../../../svg/switch.svg'
import { ReactComponent as Client } from '../../../../svg/user.svg'
import TextOverflow from '../../../components/nowrap'
import { useNotificationStore } from '../../../context/notification'

interface Props {
    onClick: () => void
}

const FamilyMenu = ({ onClick }: Props) => {
    const { home } = useContext(RoleContext)
    const location = useOrgLocation()
    const client = useRoleClient()
    const { unreadCount } = useNotificationStore()

    const [roles] = useRoles()

    return (
        <ul className="main-menu">
            <li>
                <Link onClick={onClick} to="/notifications">
                    <Bell />
                    <Trans label="Notifications" />
                    {!!unreadCount && <span className="badge">{unreadCount}</span>}
                </Link>
            </li>
            <li>
                <Link onClick={onClick} to={home}>
                    <Client />
                    <TextOverflow maxWidth={250}>{`${client?.fullName}`}</TextOverflow>
                </Link>
            </li>
            <li>
                <Link onClick={onClick} to={`/location/${location?.slug}`}>
                    <Organisation />
                    <TextOverflow maxWidth={250}>{`${location?.name}`}</TextOverflow>
                </Link>
            </li>
            <li>
                <Link onClick={onClick} to="/account/settings">
                    <Account />
                    <Trans label="My account" />
                </Link>
            </li>
            <li>
                <a
                    href={t('https://www.familynet.co.uk/help/family/')}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Help />
                    <Trans label="Help" />
                </a>
            </li>
            {roles?.length > 1 && (
                <li>
                    <Link onClick={onClick} to="/account/pick-role">
                        <Switch />
                        <Trans label="Select role" />
                    </Link>
                </li>
            )}
            <li>
                <a onClick={onClick} href="/logout">
                    <Exit />
                    <Trans label="Log out" />
                </a>
            </li>
        </ul>
    )
}

export default FamilyMenu
