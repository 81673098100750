export const locales = [
    'da-DK',
    'de-DE',
    'en-GB',
    'en-US',
    'fr-FR',
    'fr-BE',
    'nb-NO',
    'nl-NL',
    'nl-BE',
] as const
export type Locale = (typeof locales)[number]

export const languages = ['en', 'nl', 'fr', 'da', 'de', 'nb'] as const
export type Language = (typeof languages)[number]

// We extend every language with english as default
// French and Dutch in belgum extends language from France and Netherlands
// So if a phrase is not found in fr-BE, it will look in fr-FR and next en-GB
export const extendedLanguages: { [locale in Locale]: Locale[] } = {
    'en-GB': [],
    'en-US': ['en-GB'],
    'fr-FR': ['en-GB'],
    'fr-BE': ['en-GB', 'fr-FR'],
    'nl-BE': ['en-GB', 'nl-NL'],
    'nl-NL': ['en-GB'],
    'da-DK': ['en-GB'],
    'de-DE': ['en-GB'],
    'nb-NO': ['en-GB'],
}

export const localeToLanguage = (locale: Locale): Language => {
    const [language] = locale.split('-', 1)
    return language as Language
}
