import { useDebug } from '../context/debug'

interface DebugProps {
    data: any
}

const Debug = ({ data }: DebugProps) => {
    const { active, stringify } = useDebug()

    if (!active) return null

    return (
        <pre
            style={{
                whiteSpace: 'pre-wrap',
                background: '#333',
                padding: 20,
                borderRadius: 5,
                color: 'white',
                fontSize: 12,
            }}
        >
            {stringify(data)}
        </pre>
    )
}

export default Debug
