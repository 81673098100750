import { gql } from '@urql/core'
import { FileFragment, ImageFragment, VideoFragment } from './posts'
import { AuthorFragment, SimpleRoleFragment } from './user'

export const ClientPostFragment = gql`
    ${ImageFragment}
    ${FileFragment}
    ${VideoFragment}
    ${AuthorFragment}
    ${SimpleRoleFragment}
    fragment ClientPost on ClientPostNode {
        id
        body
        bodyHtml
        created
        client
        video {
            ...Video
        }
        images {
            edges {
                node {
                    ...Image
                }
            }
        }
        files {
            edges {
                node {
                    ...File
                }
            }
        }
        likes {
            totalCount
            edges {
                node {
                    ...Role
                }
            }
        }
        author {
            ...Author
        }
        rights {
            update
            delete
            createComment
            createVideo
        }
    }
`

export const clientPost = gql`
    ${ClientPostFragment}
    query clientPost($postId: ID!) {
        node(id: $postId) {
            ... on ClientPostNode {
                ...ClientPost
                thread
                client
            }
        }
    }
`

export const ClientThreadFragment = gql`
    ${ClientPostFragment}
    fragment ClientThread on ClientThreadNode {
        id
        isLocked
        commentCount
        target
        firstPost {
            ...ClientPost
        }
    }
`

export const clientPostsForThread = gql`
    ${ClientThreadFragment}
    query clientPostsForThread($threadId: ID!) {
        node(id: $threadId) {
            ... on ClientThreadNode {
                ...ClientThread
            }
        }
    }
`

export const clientForumThreads = gql`
    ${ClientThreadFragment}
    query clientForumThreads(
        $forumId: ID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $searchQuery: String
        $commentLast: Int
    ) {
        node(id: $forumId) {
            ... on ClientForumNode {
                threads(
                    first: $first
                    last: $last
                    before: $before
                    after: $after
                    searchThreadQuery: $searchQuery
                ) {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasPreviousPage
                        hasNextPage
                    }
                    edges {
                        node {
                            ...ClientThread
                            comments(last: $commentLast, searchCommentQuery: $searchQuery) {
                                pageInfo {
                                    startCursor
                                }
                                edges {
                                    node {
                                        ...ClientPost
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const createClientThread = gql`
    ${ClientPostFragment}
    mutation CreateClientThread($input: CreateClientThreadInput!) {
        createClientThread(input: $input) {
            errors {
                field
                messages
            }
            thread {
                id
                firstPost {
                    ...ClientPost
                }
            }
        }
    }
`

export const updateClientPost = gql`
    ${ClientPostFragment}
    mutation updateClientPost($input: UpdateClientPostInput!) {
        updateClientPost(input: $input) {
            errors {
                field
                messages
            }
            post {
                ...ClientPost
            }
        }
    }
`

export const deleteClientPost = gql`
    mutation deleteClientPost($input: DeleteClientPostInput!) {
        deleteClientPost(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const createClientReply = gql`
    ${ImageFragment}
    mutation createClientReply($input: CreateClientReplyInput!) {
        createClientReply(input: $input) {
            errors {
                field
                messages
            }
            post {
                id
                thread
                images {
                    edges {
                        node {
                            ...Image
                        }
                    }
                }
            }
        }
    }
`

export const clientCommentsForThread = gql`
    ${ClientPostFragment}
    ${ClientThreadFragment}
    query clientCommentsForThread(
        $threadId: ID!
        $first: Int
        $last: Int
        $before: String
        $after: String
        $searchQuery: String
    ) {
        node(id: $threadId) {
            ... on ClientThreadNode {
                ...ClientThread
                comments(
                    first: $first
                    last: $last
                    before: $before
                    after: $after
                    searchCommentQuery: $searchQuery
                ) {
                    totalCount
                    pageInfo {
                        endCursor
                        startCursor
                        hasPreviousPage
                        hasNextPage
                    }
                    edges {
                        node {
                            ...ClientPost
                        }
                    }
                }
            }
        }
    }
`

export const ClientFragment = gql`
    fragment Client on ClientNode {
        id
        slug
        firstName
        lastName
        fullName
        photo
        archived
        isArchived
        isInvite
        location
        primaryDivision
        divisions
        forum
        lifebookId
        lastPostByNurse
        reference
    }
`

export const clientQuery = gql`
    ${ClientFragment}
    query Client($slug: String, $id: ID) {
        client(slug: $slug, id: $id) {
            ...Client
            coverPhoto
            canUpdateSettings
            hasDivisionForum
            rights {
                update
                archive
                unarchive
                delete
                export
                appointmentCreate
                updateDivisions
                inviteCreate
                postCreate
            }
        }
    }
`

export const familyQuery = gql`
    query Family($client: ID!, $first: Int) {
        family(first: $first, client: $client) {
            totalCount
            edges {
                node {
                    id
                    fullName
                    photo
                    type
                    isMainContact
                }
            }
        }
    }
`

export const clientEmployeesQuery = gql`
    query employees($location: ID!, $client: ID!) {
        employees(location: $location, client: $client) {
            edges {
                node {
                    id
                    fullName
                    photo
                    type
                }
            }
        }
    }
`

export const mediaSummary = gql`
    query mediaSummary($client: ID!, $start: Date!, $end: Date!) {
        mediaSummary(client: $client, start: $start, end: $end) {
            month
            count
            thumbnail
        }
    }
`

export const videoSummary = gql`
    query videoSummary($client: ID!, $start: Date!, $end: Date!) {
        videoSummary(client: $client, start: $start, end: $end) {
            month
            count
            thumbnail
        }
    }
`

export const imagesForClient = gql`
    query imagesForClient($client: ID!, $first: Int, $start: Date, $end: Date) {
        imagesForClient(client: $client, first: $first, start: $start, end: $end) {
            edges {
                node {
                    id
                    thumbnail
                    image
                    download
                    title
                    post
                    created
                }
            }
        }
    }
`

export const videosForClient = gql`
    ${AuthorFragment}
    query videosForClient($client: ID!, $first: Int, $start: Date, $end: Date) {
        videosForClient(client: $client, first: $first, start: $start, end: $end) {
            edges {
                node {
                    created
                    thumbnail
                    url
                    pollUrl
                    status
                    name
                    duration
                    post
                    uploader {
                        ...Author
                    }
                }
            }
        }
    }
`

export const filesForClient = gql`
    query filesForClient($client: ID!, $first: Int, $start: Date, $end: Date) {
        filesForClient(client: $client, first: $first, start: $start, end: $end) {
            edges {
                node {
                    id
                    name
                    file
                    download
                    attachmentDict {
                        mimetype
                    }
                    post
                    created
                }
            }
        }
    }
`

export const updateClient = gql`
    mutation updateClient($input: UpdateClientInput!) {
        updateClient(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateClientPhotoMutation = gql`
    mutation UpdateClientPhoto($input: UpdateClientPhotoInput!) {
        updateClientPhoto(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateClientCoverPhotoMutation = gql`
    mutation UpdateClientCoverPhoto($input: UpdateClientCoverPhotoInput!) {
        updateClientCoverPhoto(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateClientMainContact = gql`
    mutation updateClientMainContact($input: UpdateClientMainContactInput!) {
        updateClientMainContact(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const updateClientDivisions = gql`
    mutation updateClientDivisions($input: UpdateClientDivisionsInput!) {
        updateClientDivisions(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const archiveClient = gql`
    mutation archiveClient($input: ArchiveClientInput!) {
        archiveClient(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const deleteClient = gql`
    mutation deleteClient($input: DeleteClientInput!) {
        deleteClient(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const restoreClient = gql`
    mutation restoreClient($input: RestoreClientInput!) {
        restoreClient(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const requestExport = gql`
    mutation requestExport($input: RequestExportInput!) {
        requestExport(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`

export const exportRequestQuery = gql`
    query exportRequest($client: ID!) {
        exportRequest(client: $client) {
            completed
            status
            url
        }
    }
`

export const createClient = gql`
    mutation createClient($input: CreateClientInput!) {
        createClient(input: $input) {
            errors {
                field
                messages
            }
            client {
                id
            }
        }
    }
`

export const updateClientPostImageTitle = gql`
    mutation updateClientPostImageTitle($input: UpdateClientPostImageTitleInput!) {
        updateClientPostImageTitle(input: $input) {
            errors {
                field
                messages
            }
        }
    }
`
