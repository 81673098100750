interface Id {
    type: string
    id: string
}

export const fromGlobalId = (uuid: string): Id => {
    const [type, id] = atob(uuid).split(':')
    return {
        type,
        id,
    }
}

export const toGlobalId = ({ type, id }: Id): string => {
    return btoa(`${type}:${id}`)
}

export const fixGlobalId = (id) => id.replace(/%3D/g, '=')

export function parseToken<ParsedToken = Record<string, any>>(token: string): ParsedToken {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}
