import { Capacitor } from '@capacitor/core'
import Cookie from 'js-cookie'
import qs from 'query-string'

interface TokenResponse {
    error?: string
    error_description?: string
    access_token?: string
    refresh_token?: string
}

interface OAuthPasswordRequest {
    username: string
    password: string
    grant_type: 'password'
    client_id: string
    client_secret: string
}

interface OAuthRefreshRequest {
    refresh_token: string
    grant_type: 'refresh_token'
    client_id: string
    client_secret?: string
}

interface OAuthAuthorizationCodeRequest {
    code: string
    code_verifier: string
    redirect_uri: string
    grant_type: 'authorization_code'
    client_id: string
}

export const endpoint = () => {
    const endpoint = process.env.REACT_APP_OAUTH_ENDPOINT || '/oauth'

    // if config file include endpoint with http we use it
    if (endpoint?.includes('http')) return endpoint

    // if not we use the current location to build the endpoint
    const url = new URL(window.location.href)
    return `${url.protocol}//${url.host.replace('app', 'login')}${endpoint}`
}

export const redirectUri = () => {
    let redirect_uri = process.env.REACT_APP_OAUTH_REDIRECT_URI || '/accept/token'
    if (Capacitor.isNativePlatform()) {
        return 'familynet://com.familynet.app/accept/token'
    }

    // if config file include endpoint with http we use it
    if (redirect_uri?.includes('http')) return redirect_uri

    const url = new URL(window.location.href)
    return `${url.protocol}//${url.host}${redirect_uri}`
}

export const tokenRequest = async (
    body: OAuthPasswordRequest | OAuthAuthorizationCodeRequest | OAuthRefreshRequest
): Promise<TokenResponse> => {
    const response = await fetch(`${endpoint()}/token/`, {
        method: 'POST',
        body: qs.stringify(body),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })

    if (response.status !== 200) {
        const body = await response.json()
        throw body
    }

    const tokens = (await response.json()) as TokenResponse
    if (tokens.access_token)
        Cookie.set('access_token', tokens.access_token, { expires: (1 / 24) * 10 }) // 10 hours
    if (tokens.refresh_token) Cookie.set('refresh_token', tokens.refresh_token, { expires: 30 }) // 30 days
    return tokens
}

interface RefreshTokensProps
    extends Omit<OAuthRefreshRequest, 'grant_type' | 'client_id' | 'client_sectret'> {}

export const refreshTokens = async ({
    refresh_token,
}: RefreshTokensProps): Promise<TokenResponse | void> => {
    try {
        return tokenRequest({
            refresh_token,
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
            client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string,
        })
    } catch {
        Cookie.remove('refresh_token')
        return
    }
}

interface GetTokenProps
    extends Omit<OAuthPasswordRequest, 'grant_type' | 'client_id' | 'client_secret'> {}

export const getTokens = async ({ username, password }: GetTokenProps): Promise<TokenResponse> => {
    return tokenRequest({
        username,
        password,
        grant_type: 'password',
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string,
    })
}

interface VerifyCodeProps extends Omit<OAuthAuthorizationCodeRequest, 'grant_type' | 'client_id'> {}

export const verifyCode = async ({
    code,
    code_verifier,
    redirect_uri,
}: VerifyCodeProps): Promise<TokenResponse> => {
    return tokenRequest({
        code,
        code_verifier,
        redirect_uri,
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
    })
}
